import { useReducer } from 'react';
import { CATEGORIES } from '../utilities/mockData';

const ACTION_GET_CATEGORIES = 'GET_CATEGORIES';
const ACTION_SCHEDULE_TASK = 'SCHEDULE_TASK';

function categoriesReducer(state, action) {
  switch (action.type) {

    case ACTION_GET_CATEGORIES:
      return state;

    case ACTION_SCHEDULE_TASK:
      return state.map((category) => {
        category.tasks.forEach(task => {
          if (task.id === action.taskId) {
            task.start = action.start;
          }
        });
        return category;
      })

    default:
      return state;
  }
}

function useCategories() {
  const [categories, dispatch] = useReducer(categoriesReducer, CATEGORIES);

  const scheduleTask = (taskId, start) => {
    dispatch({ type: ACTION_SCHEDULE_TASK, taskId, start });
  }

  return {
    categories,
    scheduleTask,
  }
}


export default useCategories;
