import RegainView from './RegainView';

function App() {
  return (
    <div className="desktop:container desktop:mx-auto p-4 md:bg-black">
      <div className="mb-12 text-center">
        <h1 className="font-serif mb-2 desktop:text-h3 text-h4">
          re<span className="text-green">gain</span>.
        </h1>
        <h3 className="text-grey">
          super simple calendar-based daily planner for hyper focused people
        </h3>
      </div>
      <RegainView />
    </div>
  );
}

export default App;
