import { DndContext, useSensor, MouseSensor, useSensors } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useState, createContext } from 'react';
import Calendar from '../Components/Calendar/Calendar';
import Inbox from '../Components/Inbox/Inbox';
import useCategories from '../reducers/categories';

export const RegainContext = createContext({ categories: { data: [], scheduleTask: () => null } });

function RegainView() {
  const { categories, scheduleTask } = useCategories();

  const [isInboxCollapsed, setIsInboxCollapsed] = useState(false);


  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(
    mouseSensor,
  );

  const onDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.data.current.supports.includes(over.data.current.type)) {
      scheduleTask(active.id, over.data.current.start);
    }
  }

  const onCollapseButtonPressed = () => setIsInboxCollapsed(!isInboxCollapsed);

  return (
    <RegainContext.Provider value={{ categories: { data: categories, scheduleTask } }}>
      <div className="flex border-porcelain border-2 border-t-4 rounded-lg shadow-2xl bg-porcelain">
        <DndContext modifiers={[restrictToWindowEdges]} onDragEnd={onDragEnd} sensors={sensors}>
          <div className="w-full">
            <Calendar />
          </div>
          <div className={!isInboxCollapsed ? 'w-1/4 p-2' : 'w-10 p-2'}>
            <Inbox isCollapsed={isInboxCollapsed} onCollapseButtonPressed={onCollapseButtonPressed} />
          </div>
        </DndContext>
      </div>
    </RegainContext.Provider>
  );
}

export default RegainView;
