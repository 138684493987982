import { useDroppable } from '@dnd-kit/core';
import Task from './Task';

function Category({ id, name, color, tasks }) {
  const { setNodeRef } = useDroppable({
    id,
    data: {
      type: id,
    }
  });
  return (
    <div className="p-4 inset-2 rounded-md mb-4 mx-2" ref={setNodeRef}
         style={{ backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b})` }}>
      <div className="flex justify-between mb-2 items-start">
        <h5 className="font-serif font-bold">
          {name}
        </h5>
        {
          tasks.length >= 4 && (
            <a href="#" className="link text-caption !border-b">see all</a>
          )
        }
      </div>
      <div>
        {tasks.length === 0 && (
          <div className="text-overline text-center mt-2">
            No unscheduled tasks
          </div>
        )}
        {tasks.map(task => (
          <Task
            key={task.id}
            id={task.id}
            title={task.title}
            description={task.description}
            color={color}
            categoryId={id}
          />
        ))}
      </div>
    </div>
  );
}

export default Category;
