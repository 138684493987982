import { SHORT_DATE_FORMAT, WEEKDAY_FORMAT, START_HOUR, END_HOUR, HOUR_HEIGHT } from '../../utilities/constants';
import { getHours } from '../../utilities/time';
import Slot from './Slot';

const hours = getHours(START_HOUR, END_HOUR, true);

function Day({ date, slots, isLast }) {
  return (
    <div className={`text-center flex-grow border-porcelain ${!isLast ? 'border-r-2' : ''}`}>
      <h2 className="py-2 text-green-secondary font-bold border-porcelain border-b overflow-hidden whitespace-nowrap">
        {date.format(WEEKDAY_FORMAT)}
        <span className="text-grey font-normal pl-2">
          {date.format(SHORT_DATE_FORMAT)}
        </span>
      </h2>
      <div className="relative">
        <div className="relative">
          {
            hours
              .map((hour, k) => {
                return (
                  <div
                    key={hour}
                    style={{ height: `${HOUR_HEIGHT}rem` }}
                    className={`bg-white border-porcelain py-1 border-n ${k !== hours.length - 1 ? 'border-b' : ''}`}
                  />
                )
              })
          }
        </div>
        <div className="absolute top-0 left-0 right-0">
          {
            slots.map(slot => <Slot slot={slot} key={slot.start} />)
          }
        </div>
      </div>
    </div>
  );
}

export default Day;
