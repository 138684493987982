import { useDroppable } from '@dnd-kit/core';
import { useState, useEffect } from 'react';
import { HOUR_HEIGHT, START_HOUR } from '../../utilities/constants';
import { getSlotDuration, getSlotOffset } from '../../utilities/time';
import Task from '../Inbox/Task';

const DEFAULT_OPACITY = 0.8;
const LOWERED_OPACITY = 0.4;

function Slot({ slot }) {
  const top = getSlotOffset(slot, START_HOUR);
  const duration = getSlotDuration(slot);

  const [isSuitable, setIsSuitable] = useState(null);
  const [opacity, setOpacity] = useState(DEFAULT_OPACITY);

  const { over, active, setNodeRef } = useDroppable({
    id: slot.start,
    data: {
      type: slot.category.id,
      start: slot.start
    }
  });

  useEffect(() => {
    if (active) {
      setIsSuitable(active.data.current.supports?.includes(slot.category.id));
    } else {
      setIsSuitable(null);
    }
  }, [slot, over, active]);

  useEffect(() => {
    if (isSuitable !== null) {
      setOpacity(isSuitable ? DEFAULT_OPACITY : LOWERED_OPACITY)
    } else {
      setOpacity(DEFAULT_OPACITY);
    }
  }, [isSuitable])

  return (
    <div className="absolute"
         style={{ top: `${HOUR_HEIGHT * top}rem`, left: 0, right: 0}}>
      <div ref={setNodeRef}
           className={`border-y border-porcelain text-left p-2 transition-opacity ${isSuitable && 'shadow-lg'}`}
           style={{
             width: '100%',
             height: `${HOUR_HEIGHT * duration}rem`,
             backgroundColor: `rgba(${slot.category.color.r}, ${slot.category.color.g}, ${slot.category.color.b}, ${opacity})`,
           }}>
        {slot.tasks.map(task => (
          <Task
            key={task.id}
            id={task.id}
            title={task.title}
            description={task.description}
            color={slot.category.color}
            categoryId={slot.category.id}
          />
        ))}
      </div>
    </div>
  )
}

export default Slot;
