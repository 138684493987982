import { useDraggable } from '@dnd-kit/core';

function Task({ id, title, description, color, categoryId }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data: {
      supports: [categoryId],
    }
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
    padding: '4px 8px',
    zIndex: 999,
  } : undefined;

  return (
    <div
      style={style}
      className={`relative z-20 text-caption !opacity-100 px-2`}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      &bull; {title}
    </div>
  );
}

export default Task;
