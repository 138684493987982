function Hour({ hour, isLast }) {
  return (
    <div
      className={`h-14 bg-white border-porcelain border-r py-1 text-center text-caption ${!isLast ? 'border-b' : ''}`}
      key={hour}>
      {hour}
    </div>
  );
}

export default Hour;
