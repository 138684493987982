import moment from 'moment';
import { useState, useEffect, useContext } from 'react';
import { RegainContext } from '../../Containers/RegainView';
import { DAYS_VISIBLE, START_HOUR, END_HOUR } from '../../utilities/constants';
import { getHours } from '../../utilities/time';
import Day from './Day';
import Hour from './Hours';


const hours = getHours(START_HOUR, END_HOUR);

function Calendar() {
  const RegainApp = useContext(RegainContext);

  const [startDate, setStartDate] = useState(moment().toDate());

  const [days, setDays] = useState([]);

  const onNextWeekPressed = () => setStartDate(moment(startDate).add(DAYS_VISIBLE, 'days').toDate());
  const onPrevWeekPressed = () => setStartDate(moment(startDate).add(-DAYS_VISIBLE, 'days').toDate());

  useEffect(() => {
    // Build array of days
    const items = Array.from({ length: DAYS_VISIBLE }, (_, k) => ({
      date: moment(startDate).add(k, 'days'),
      slots: [],
    }));

    // Attach dedicated slots per category for these days
    RegainApp.categories.data.forEach((category) => {
      items.forEach(item => {
        item.slots = item.slots.concat(
          category.slots
            .filter(slot => moment(slot.start).isSame(item.date, 'day'))
            .map(slot => ({ ...slot, category: category, tasks: [] }))
        )
      })
    })

    // Set already scheduled tasks
    items.forEach(item => {
      item.slots.forEach(slot => {
        slot.tasks = slot.tasks.concat(
          RegainApp.categories.data.reduce((acc, category) => {
            if (slot.category.id === category.id) {
              acc = acc.concat(category.tasks.filter(task => task.start && moment(task.start).isSameOrAfter(slot.start) && moment(task.start).isBefore(slot.end)))
            }
            return acc;
          }, [])
        )
      })
    })
    setDays(items);
  }, [startDate, RegainApp.categories.data]);

  return (
    <div className="flex bg-white">
      <div className="w-24">
        <div className="flex justify-around py-2 mb-0.5 items-center  bg-porcelain">
          <button onClick={onPrevWeekPressed}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button onClick={onNextWeekPressed}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
        {hours.map((hour, k) => <Hour key={hour} hour={hour} isLast={k === hours.length - 1} />)}
      </div>
      <div className="w-full flex">
        {days.map((day, k) => <Day
          date={day.date}
          slots={day.slots}
          key={day.date.format('x')}
          isLast={k === days.length - 1} />
        )}
      </div>
    </div>
  );
}

export default Calendar;
