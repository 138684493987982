/**
 * Constants that might become settings.
 */

export const START_HOUR = 8;
export const END_HOUR = 21;

export const DAYS_VISIBLE = 4;
export const FULL_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_DATE_FORMAT = 'D/MM';
export const TIME_FORMAT = 'h:mm A';
export const WEEKDAY_FORMAT = 'dddd';

export const HOUR_HEIGHT = 1.75; // in rem
