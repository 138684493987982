/**
 * Data later to be replaced with API / Data Storage
 */
export const CATEGORIES = [
  {
    id: "personal",
    name: "Personal",
    color: {
      r: 238,
      g: 185,
      b: 251
    },
    tasks: [
      {
        order: 0,
        id: "pay-electricity-bill",
        title: "Pay electricity bill",
        description: null,
      },
      {
        order: 1,
        id: "rent-a-car",
        title: "Rent a car",
        description: null,
        start: "2022-08-31T19:30:00+0300"
      },
    ],
    slots: [
      {
        start: "2022-08-30T08:30:00+0300",
        end: "2022-08-30T11:00:00+0300",
      },
      {

        start: "2022-08-31T18:30:00+0300",
        end: "2022-08-31T20:00:00+0300",
      },
      {
        start: "2022-09-02T15:00:00+0300",
        end: "2022-09-02T16:00:00+0300",
      },
    ]
  },
  {
    id: "work",
    name: "Work",
    color: {
      r: 160,
      g: 228,
      b: 179
    },
    tasks: [
      {
        order: 0,
        id: "create-a-code-quality-pipeline",
        title: "Create a Code Quality pipeline",
        description: null,
      },
      {
        order: 1,
        id: "implement-that-stupid-feature",
        title: "Implement that stupid feature",
        description: null,
      },
      {
        order: 2,
        id: "fix-that-pesky-bug",
        title: "Fix that pesky bug",
        description: null,
      },
    ],
    slots: [
      {
        start: "2022-08-30T14:30:00+0300",
        end: "2022-08-30T16:00:00+0300",
      },
      {

        start: "2022-08-31T08:00:00+0300",
        end: "2022-08-31T12:00:00+0300",
      },
      {
        start: "2022-09-01T14:30:00+0300",
        end: "2022-09-01T16:00:00+0300",
      },
      {
        start: "2022-09-02T09:00:00+0300",
        end: "2022-09-02T11:30:00+0300",
      },
    ]
  },
  {
    id: "study",
    name: "Study",
    color: {
      r: 254,
      g: 148,
      b: 154
    },
    tasks: [
      {
        order: 0,
        id: "submit-homework-for-ml301-4",
        title: "Submit homework for ML301.4",
        description: null,
      },
      {
        order: 1,
        id: "write-paper-for-be-410j",
        title: "Write paper for BE.410J",
        description: null,
      },
      {
        order: 2,
        id: "research-for-esd-72",
        title: "Research for ESD.72",
        description: null,
      },
    ],
    slots: [
      {
        start: "2022-08-30T16:30:00+0300",
        end: "2022-08-30T20:00:00+0300",
      },
      {
        start: "2022-08-31T15:00:00+0300",
        end: "2022-08-31T17:30:00+0300",
      },
      {
        start: "2022-09-01T09:00:00+0300",
        end: "2022-09-01T13:30:00+0300",
      },
      {
        start: "2022-09-02T12:00:00+0300",
        end: "2022-09-02T14:30:00+0300",
      },
      {
        start: "2022-09-02T16:30:00+0300",
        end: "2022-09-02T19:30:00+0300",
      },
    ]
  },
  {
    id: "freelance",
    name: "Freelance",
    color: {
      r: 234,
      g: 220,
      b: 93
    },
    tasks: [
      {
        order: 0,
        id: "send-estimate-to-client",
        title: "Sent estimate to client",
        description: null,
      },
      {
        order: 1,
        id: "prepare-invoices",
        title: "Prepare invoices",
        description: null,
      },
      {
        order: 2,
        id: "write-assignment-for-designers",
        title: "Write assignment for designers",
        description: null,
      },
    ],
    slots: [
      {
        start: "2022-08-30T11:30:00+0300",
        end: "2022-08-30T13:30:00+0300",
      },
      {

        start: "2022-08-31T12:30:00+0300",
        end: "2022-08-31T14:30:00+0300",
      },
      {
        start: "2022-09-01T16:30:00+0300",
        end: "2022-09-01T19:00:00+0300",
      },
    ],
  },
];
