import moment from 'moment';
import { FULL_DATE_FORMAT, TIME_FORMAT, START_HOUR, END_HOUR } from './constants';

/**
 *
 * @param start
 * @param end
 * @param includeHalves
 * @returns {*[]}
 */
export const getHours = (start = START_HOUR, end = END_HOUR, includeHalves = false) => {
  const items = []
  for (let hour = start; hour < end; hour++) {
    items.push(moment({ hour }).format(TIME_FORMAT));
    if (includeHalves) {
      items.push(moment({ hour, minute: 30 }).format('h:mm A'))
    }
  }
  return items;
};

/**
 *
 * @param date moment object
 * @param time eg.10:00 AM
 * @param slots
 */
export const findSlotByTime = (date, time, slots) => {
  const dateTimeString = `${date.format(FULL_DATE_FORMAT)} ${time}`;
  const dateTime = moment(dateTimeString, `${FULL_DATE_FORMAT} ${TIME_FORMAT}`);

  return slots.find(slot => dateTime.isSameOrAfter(slot.start) && dateTime.isBefore(slot.end));
};


/**
 * Get difference between start and end in "slots".
 *
 * @param {moment.Moment} start
 * @param {moment.Moment} end
 */
export const diffStartEnd = (start, end) => {
  return(end.format('x') - start.format('x'))/ 1000 / 60 / 30;
};


/**
 * Returns slot duration in number of 30min intervals
 *
 * @param slot
 * @returns {number}
 */
export const getSlotDuration = (slot) => {
  return diffStartEnd(moment(slot.start), moment(slot.end));
}

/**
 * Returns slot offset in Day view based on startTime
 *
 * @param slot
 * @param startTime
 */
export const getSlotOffset = (slot, startTime) => {
  const slotStart = moment(slot.start);
  const calendarStartString = `${slotStart.format(FULL_DATE_FORMAT)} ${startTime}`;
  const calendarStart = moment(calendarStartString, `${FULL_DATE_FORMAT} ${TIME_FORMAT}`);

  return diffStartEnd(calendarStart, slotStart);
};
