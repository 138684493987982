import { useContext } from 'react';
import { RegainContext } from '../../Containers/RegainView';
import Category from './Category';

function Inbox({ isCollapsed, onCollapseButtonPressed }) {
  const RegainApp = useContext(RegainContext);

  return (
    <div className="flex flex-col justify-between h-full relative">
      <div>
        {!isCollapsed && (
          RegainApp.categories.data.map(category => <Category
              key={category.id}
              id={category.id}
              name={category.name}
              color={category.color}
              tasks={category.tasks.filter(task => !task.start)}
            />
          )
        )}
      </div>
      <div className="flex mb-2">
        <button className={!isCollapsed ? '-rotate-90' : 'rotate-90'} onClick={onCollapseButtonPressed}>
          <svg className="w-6 h-6 text-green-secondary" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
          </svg>
        </button>
        {!isCollapsed && (
          <h2 className="text-h5 ml-2">
            Inbox
          </h2>
        )}
      </div>
    </div>
  );
}

export default Inbox;
